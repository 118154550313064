'use client';

import persistenceService from '@/lib/utils/persistenceService';
import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { STORAGE_KEY } from './actions';

export type Action = any;
export type State = any;

const setState: Dispatch<SetStateAction<Record<string, unknown>>> = () => {};
export const initialState: State = persistenceService.get(STORAGE_KEY)
  ? persistenceService.get(STORAGE_KEY)
  : {};

const GlobalStoreStateContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: setState,
});

export const useGlobalStore = () => useContext(GlobalStoreStateContext);

export default GlobalStoreStateContext;
