import { getPartners } from '../partners/settings/getSettings';

export const getValidSubdomain = (host?: string | null) => {
  const partners = getPartners();
  let subdomain: string | null = null;
  if (!host && typeof window !== 'undefined') {
    // On client side, get the host from window
    host = window.location.host;
  }
  if (host && host.includes('.')) {
    const candidate = host.split(/\.liberis|\.localhost/)[0];
    
    if (
      candidate &&
      !candidate.includes('localhost') &&
      partners.indexOf(candidate) !== -1
    ) {
      // Valid candidate
      subdomain = candidate;
    }
  }
  return subdomain;
};
