'use client';

import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import {
  ApiCallMetadata,
  ErrorMetadata,
  EventTypes,
  PageViewMetadata,
  SLIMetadata,
  UserEventMetadata,
} from '../../hooks/useLogEvent';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { merge } from 'lodash';
import { usePartner } from '@/lib/partners/usePartner/client';

if (!process.env.DISABLE_DD) {
  console.log('DATADOG IS RUNNING');
  datadogRum.init({
    applicationId: getEnvVar('NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID')!,
    clientToken: getEnvVar('NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN')!,
    site: getEnvVar('NEXT_PUBLIC_DATADOG_SITE')!,
    service: getEnvVar('NEXT_PUBLIC_DATADOG_SERVICE')!,
    env: process.env.NODE_ENV,
    version: process.env.NEXT_PUBLIC_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackViewsManually: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogLogs.init({
    clientToken: getEnvVar('NEXT_PUBLIC_DATADOG_CLIENT_TOKEN')!,
    site: getEnvVar('NEXT_PUBLIC_DATADOG_SITE')!,
    service: getEnvVar('NEXT_PUBLIC_DATADOG_SERVICE')!,
    env: process.env.NODE_ENV,
    forwardErrorsToLogs: false,
    sessionSampleRate: 100,
    version: process.env.NEXT_PUBLIC_VERSION,
  });
}
else {
  console.log('DATADOG IS NOT RUNNING');
}

export const logServiceLevelIndicator = (
  sliName: string,
  sliValue: number,
  metadata: SLIMetadata,
) => {
  const logData = {
    team: 'MX',
    eventType: EventTypes.SLI,
    [sliName]: sliValue,
    metadata,
  };

  if (!process.env.DISABLE_DD) {
    datadogLogs.logger.log(`LOG_SLI (${sliName}: ${sliValue})`, logData);
  }
};

export const logPageView = (pageName: string, metadata: PageViewMetadata) => {
  const logData = {
    team: 'MX',
    eventType: EventTypes.PAGE_VIEW,
    pageName,
    metadata,
  };

  if (!process.env.DISABLE_DD) {
    datadogLogs.logger.log(`PAGE_VIEW: ${pageName}`, logData);
  }
};

export const logError = (
  error: Error,
  metadata: ErrorMetadata,
  traceId?: string,
  spanId?: string,
) => {
  let logData: any = {
    team: 'MX',
    eventType: EventTypes.ERROR,
    metadata,
  };

  if (traceId && spanId) {
    logData = {
      ...logData,
      trace_id: traceId,
      'dd.trace_id': traceId,
      span_id: spanId,
      'dd.span_id': spanId,
    };

    if (!process.env.DISABLE_DD) {
      datadogLogs.logger.error(`ERROR: ${error.message}`, logData, error);
      return;
    }
  }

  if (!process.env.DISABLE_DD) {
    datadogLogs.logger.error(`ERROR: ${error}`, logData, error);
  }
};

export const logUserEvent = (
  eventName: string,
  metadata: UserEventMetadata,
  value?: string
) => {
  const logData = {
    team: 'MX',
    eventType: EventTypes.USER_EVENT,
    metadata,
    eventName,
    eventValue: value ?? 'no value provided'
  };

  if (!process.env.DISABLE_DD) {
    datadogLogs.logger.log(`USER_EVENT: ${eventName}`, logData);
  }
};

export const logApiCall = (
  requestName: string,
  metadata: ApiCallMetadata,
  traceId: string,
  spanId: string,
) => {
  const logData = {
    team: 'MX',
    eventType: EventTypes.API_CALL,
    metadata,
    requestName,
    trace_id: traceId,
    'dd.trace_id': traceId,
    span_id: spanId,
    'dd.span_id': spanId,
  };

  if (!process.env.DISABLE_DD) {
    datadogLogs.logger.log(`API_CALL: ${requestName}`, logData);
  }
};

export default function DatadogInit() {
  const pathname = usePathname();
  const partner = usePartner();

  useEffect(() => {
    if (!process.env.DISABLE_DD) {
      datadogRum.startView(pathname);
    }
  }, [pathname]);

  useEffect(() => {
    if (!process.env.DISABLE_DD) {
      setDDSessionAttribute('partner', partner);
    }
  },[partner]);
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}

export function setDDSessionAttribute(key: string, value: any) {
  if (!process.env.DISABLE_DD) {
    const currentSession: any = datadogRum.getGlobalContext().session || {};
    const updatedSession = merge({}, currentSession, { [key]: value });
    datadogRum.setGlobalContextProperty('session', updatedSession);
  }
}
