'use client';

import type { EnvVars } from '@/lib/partners/public/vars/types/EnvVars';
import publicEnvVars from '../../../partners/public/vars/envVars.json';
import localPublicEnvVars from '../../../partners/public/vars/envVars.local.json';
import { getValidSubdomain } from '../../getValidSubdomain';
import { getEnvVarByPartner } from '../getEnvVarByPartner';

const getEnvVar = (envVar: string, partner?: string) => {
  const isProd = (typeof window !== 'undefined') && window.location?.host?.includes('liberis.com');
  const nextPublicEnvVars = isProd ? publicEnvVars as EnvVars: localPublicEnvVars as EnvVars;

  if (!partner) {
    partner = getValidSubdomain() ?? 'liberis';
  };

  return getEnvVarByPartner(envVar, partner, nextPublicEnvVars);
};

export default getEnvVar;
