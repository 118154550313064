'use client';

import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';

let isBrazeInitialized = false;
let brazeRef: {
    initialize: (apiKey: string, options: any) => boolean;
    getUser: () => any;
    logCustomEvent: (eventName: string, properties?: object) => boolean;
    enableSDK: () => void;
} | undefined;

const getBraze = async () => {
    if (brazeRef) return brazeRef;
    brazeRef = await import('@braze/web-sdk');
    return brazeRef;
}

const getBrazeUser = async () => {
  if (!isBrazeInitialized) {
    return null;
  }
  const user = (await getBraze()).getUser();
  if (!user) {
    console.warn('Braze user not found due to SDK not initialized');
    return null;
  }
  return user;
};

export const brazeInit = async () => {
  const apiKey = getEnvVar('NEXT_PUBLIC_BRAZE_API_KEY');
  const baseUrl = getEnvVar('NEXT_PUBLIC_BRAZE_BASE_URL');
  (await getBraze()).enableSDK();
  if (!(await getBraze()).initialize(apiKey!, { baseUrl: baseUrl! })) {
    console.warn('Braze SDK not initialized due to missing env variables');
  }
};

export const setBrazeUserEmail = async (email: string) => {
  if (!email) {
    console.warn('Email not provided, skipping Braze user email update');
    return;
  }

  if(!isBrazeInitialized){
      await brazeInit();
      isBrazeInitialized = true;
  }
  const user = (await getBraze()).getUser();
  user!.setEmail(email);
};

export const setBrazeKeyValuePair = async (key: string, value: any) => {
  const user = await getBrazeUser();
  if (user) {
    user.setCustomUserAttribute(key, value);
  }
};

export const sendBrazeEvent = async (
  eventName: string,
  properties?: object,
) => {
  const user = await getBrazeUser();

  if (user) {
    const event = (await getBraze()).logCustomEvent(eventName, properties);
    if (!event) {
      console.warn('Braze event not sent due to SDK not initialized');
    }
  }
};
